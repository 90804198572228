
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










































































.filters-panel-button {
  @include liquid(font-size, 20px, 36px);

  display: block;
  margin-right: auto;
  margin-bottom: $hero-subtitle-margin-top;
  margin-left: auto;
  line-height: 1.6;
}

.active-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 5.9rem;

  li:not(:last-of-type) {
    margin-right: 0.9rem;
    margin-bottom: 0.9rem;
  }
}

.active-filter {
  display: flex;
  align-items: center;
  padding: 0.9rem 1.6rem;
  font-size: 1.4rem;
  line-height: 1;
  border: 1px currentColor solid;
  border-radius: 2.5rem;

  path {
    fill: currentColor;
  }
}

.active-filter__close {
  width: 1.2rem;
  height: 1.2rem;
  margin-left: 0.8rem;
}

.join-us-link {
  margin-bottom: 5.2rem;
  font-size: 1.8rem;
  text-align: right;

  a {
    --underline-height: 1px;
  }
}
