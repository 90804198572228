
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































































































.designers-filters {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.filters-step {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.filter-list {
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
}

.filter-list__item {
  border-bottom: 1px currentColor solid;

  &:hover,
  &:focus-within {
    .filter:not(.active) {
      .filter__dot {
        &::before {
          transform: translateX(-50%);
        }
      }
    }
  }
}

.filter {
  @include liquid(font-size, 18px, 22px);

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.8rem 0;
  cursor: pointer;

  // First level filters (Design and Mode) looks exactly like input but are actually buttons.
  // Because they look alike, they should behave alike so we're adding a focus ring on focus
  &:is(button):focus {
    @extend %has-focus;
  }

  &.active {
    .filter__dot {
      &::before {
        transform: translateX(0%);
      }
    }
  }

  &:focus,
  &:hover {
    text-decoration: none;
  }
}

.filter__input {
  position: absolute;
  z-index: -1;
  top: 0;
  right: 0;
  clip-path: inset(100% 100% 0% 0%);

  &:focus {
    + .filter {
      @extend %has-focus;
    }
  }
}

.filter__dot {
  @include liquid(width, 16px, 26px);
  @include liquid(height, 16px, 26px);

  position: relative;
  overflow: hidden;
  border: 1px currentColor solid;
  border-radius: 50%;
  clip-path: circle(
    50% at 50% 50%
  ); // Fix visible overflow during transform on safari

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: currentColor;
    border-radius: 50%;
    transform: translateX(-100%);
    transition: transform $transition-duration $easing;
  }
}

.go-back {
  justify-content: flex-start;
}

.go-back__svg {
  @include liquid(width, 16px, 18px);
  @include liquid(height, 16px, 18px);

  margin-right: 1.4rem;
  stroke: currentColor;
}

.apply-filters-button {
  @include liquid(font-size, 14px, 18px);

  display: block;
  width: 100%;
  margin-top: auto;
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  border: 1px currentColor solid;
  border-radius: 50px; // Arbitrary value
}
